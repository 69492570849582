import React from 'react';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import { graphql } from 'gatsby';

import { Grid } from '@core';
import {
  ImageCore,
  Layout,
  LinkWrapper,
  SEO,
  SectionContainerSC as SectionContainer,
  Text,
} from '@components';
import { AdoptionIndexHeader, CountryRatingsSection, CountryTableSection } from '@containers';
import atlasLogoBlueBold from '@images/logos/atlas-logo-bold.svg';
import { ADOPTION_TABLE_DATA, PAGE_DATA } from '@shared/constants/pages/adoption-index';
import { PRICING_LINK } from '@shared/constants/constants';

const { metaData, header, about, methodology, countryRatings, footer } = PAGE_DATA;

const Container = styled.div`
  background-color: #f1f7fa;
`;

const VpnAdoptionIndex = (props) => {
  const { t } = useTranslation();

  return (
    <Layout
      location={props.location}
      navProps={{ isHidden: true }}
      footerProps={{ isHidden: true }}
    >
      <SEO {...metaData(t)} />
      <Container>
        <AdoptionIndexHeader headerData={header(t)} aboutData={about(t)} />
        <CountryRatingsSection
          {...countryRatings(t)}
          countryData={ADOPTION_TABLE_DATA(t).countryData}
        />
        <CountryTableSection {...ADOPTION_TABLE_DATA(t)} />
        <SectionContainer
          {...methodology(t)}
          containerProps={{ backgroundColor: '#f1f7fa' }}
          textWrapperProps={{ pb: { _: 0, sm: 0 } }}
          titleProps={{ fontFamily: 'default' }}
        />
        <SectionContainer containerProps={{ backgroundColor: '#f1f7fa' }}>
          <Grid.Container>
            <Grid.Row
              alignItems="center"
              justifyContent="center"
              flexDirection={{ _: 'column', lg: 'row' }}
            >
              <Text.Body1 px={36} mt={24}>
                {footer(t).title}
              </Text.Body1>
              <LinkWrapper link={PRICING_LINK}>
                <ImageCore
                  src={atlasLogoBlueBold}
                  alt="Atlas VPN logo"
                  width={214}
                  height={55}
                  mt={24}
                />
              </LinkWrapper>
            </Grid.Row>
          </Grid.Container>
        </SectionContainer>
      </Container>
    </Layout>
  );
};

export default VpnAdoptionIndex;

export const query = graphql`
  query ($language: String!) {
    locales: allLocale(
      filter: {
        language: { eq: $language }
        ns: { in: ["common", "navigation", "vpnAdoptionIndex"] }
      }
    ) {
      edges {
        node {
          ns
          data
          language
        }
      }
    }
  }
`;
